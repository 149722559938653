exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gdansk-kowale-akademia-tsx": () => import("./../../../src/pages/gdansk-kowale/akademia.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-akademia-tsx" */),
  "component---src-pages-gdansk-kowale-galeria-tsx": () => import("./../../../src/pages/gdansk-kowale/galeria.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-galeria-tsx" */),
  "component---src-pages-gdansk-kowale-grafik-zajec-tsx": () => import("./../../../src/pages/gdansk-kowale/grafik-zajec.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-grafik-zajec-tsx" */),
  "component---src-pages-gdansk-kowale-index-tsx": () => import("./../../../src/pages/gdansk-kowale/index.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-index-tsx" */),
  "component---src-pages-gdansk-kowale-karnet-success-tsx": () => import("./../../../src/pages/gdansk-kowale/karnet-success.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-karnet-success-tsx" */),
  "component---src-pages-gdansk-kowale-karnet-tsx": () => import("./../../../src/pages/gdansk-kowale/karnet.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-karnet-tsx" */),
  "component---src-pages-gdansk-kowale-regulamin-i-polityka-prywatnosci-tsx": () => import("./../../../src/pages/gdansk-kowale/regulamin-i-polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-regulamin-i-polityka-prywatnosci-tsx" */),
  "component---src-pages-gdansk-kowale-uslugi-tsx": () => import("./../../../src/pages/gdansk-kowale/uslugi.tsx" /* webpackChunkName: "component---src-pages-gdansk-kowale-uslugi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reda-akademia-tsx": () => import("./../../../src/pages/reda/akademia.tsx" /* webpackChunkName: "component---src-pages-reda-akademia-tsx" */),
  "component---src-pages-reda-galeria-tsx": () => import("./../../../src/pages/reda/galeria.tsx" /* webpackChunkName: "component---src-pages-reda-galeria-tsx" */),
  "component---src-pages-reda-grafik-zajec-tsx": () => import("./../../../src/pages/reda/grafik-zajec.tsx" /* webpackChunkName: "component---src-pages-reda-grafik-zajec-tsx" */),
  "component---src-pages-reda-index-tsx": () => import("./../../../src/pages/reda/index.tsx" /* webpackChunkName: "component---src-pages-reda-index-tsx" */),
  "component---src-pages-reda-karnet-success-tsx": () => import("./../../../src/pages/reda/karnet-success.tsx" /* webpackChunkName: "component---src-pages-reda-karnet-success-tsx" */),
  "component---src-pages-reda-karnet-tsx": () => import("./../../../src/pages/reda/karnet.tsx" /* webpackChunkName: "component---src-pages-reda-karnet-tsx" */),
  "component---src-pages-reda-regulamin-i-polityka-prywatnosci-tsx": () => import("./../../../src/pages/reda/regulamin-i-polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-reda-regulamin-i-polityka-prywatnosci-tsx" */),
  "component---src-pages-reda-uslugi-tsx": () => import("./../../../src/pages/reda/uslugi.tsx" /* webpackChunkName: "component---src-pages-reda-uslugi-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */)
}

